<template lang="pug">
.home
    .banner
      .banner-inner.main
        h1 助力传统企业AI转型
        p.sub 小枝科技为您赋能
        div.btn.flex-center(@click="showContact = true") 联系我们
    .service
      .service-inner.main
        .common-title 服务项目
      .service-list
        .service-list-inner.clearfix
          .service-item(v-for="(service, index) in 6" :key="index")
            .service-item-inner
              h2.service-title 软件开发
              p.service-subject.ellipsis--l2 小枝具有丰富的定制软件开发经验，致力于为客户提供优质的端对端的IT技术解决方案>
              .service-more 了解更多 >
    .industry(:class="{ 'industry-fade': transite }")
      .industry-inner.main
        .industry-title 产业领域
        .industry-main
          .industry-subtitle {{expertData.name}}
          .industry-subject {{expertData.content}}
          .industry-btn.flex-center 查看更多
        .nav
          .nav-inner
            .nav-item(:class="{ 'nav-item-active': expertActive == navIndex }" v-for="(nav, navIndex) in navs" :key="navIndex" @click="handleChangeExpert(navIndex)") {{ nav.label }}
    .partner
      .partner-inner
        .industry-title 合作伙伴
        img(src="@/assets/images/partner.png")
    .expert
      .expert-inner
        .industry-title 专家服务
        p.expert-desc 如果您有技术开发需求，氪体提交信息<br/>如果您有技术开发需求，氪体提交信息，进行咨询报价，我们商务人员会尽快联系您。
        .expert-btn.flex-center(@click="showContact = true") 立即联系
    z-cooperation(:open.sync="showContact")
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  data() {
    return {
      navs: [
        { label: "教育"},
        { label: "电商/零售" },
        { label: "互联网" },
        { label: "汽车" },
        { label: "物流" },
        { label: "财务" },
        { label: "制造业" },
      ],
      experts: [
        { title: "产业领域", name: "教育",  content: "依托腾讯云技术，助理智慧教育行业产业升级依托腾讯云技术，助理智慧教育行业产业升级依托腾讯云技术，助理智慧教育行业产业升级依托腾讯云技术，助理智慧教育行业产业升级依托腾讯云技术，助理智慧教育行业产业升级依托腾讯云技术，助理智慧教育行业产业升级依托腾讯云技术，助理智慧教育行业产业升级。"},
        { title: "产业领域2", name: "教育2",  content: "依托腾讯云技术，助理智慧教lnlrjhnrnh业29659259育行业产业升级。"},
        { title: "产业领域", name: "教育3",  content: "依托腾讯云技术，助理智慧教lnlrjhnrnh业29659259育行业产业升级。"},
        { title: "产业领域2", name: "教育4",  content: "依托腾讯云技术，助理智慧教lnlrjhnrnh业29659259育行业产业升级。"},
        { title: "产业领域2", name: "教育5",  content: "依托腾讯云技术，助理智慧教lnlrjhnrnh业29659259育行业产业升级。"},
        { title: "产业领域2", name: "教6",  content: "依托腾讯云技术，助理智慧教lnlrjhnrnh业29659259育行业产业升级。"},
        { title: "产业领域2", name: "教7",  content: "依托腾讯云技术，助理智慧教lnlrjhnrnh业29659259育行业产业升级。"},
        { title: "产业领域2", name: "教育8",  content: "依托腾讯云技术，助理智慧教lnlrjhnrnh业29659259育行业产业升级。"},
      ],
      expertActive: 0,
      navCode: "education",
      showContact: false,
      transite: false,
    }
  },
  computed: {
    expertData() {
      return this.experts[this.expertActive]
    },
  },
  methods: {
    handleChangeExpert(index) {
      this.expertActive = index
      this.transite = true
    },
  },
};
</script>
<style lang="stylus" scoped>
.banner
  height 370px
  background url("../assets/images/banner.png") center no-repeat
  background-size cover
  color #fff
  h1
    margin 0
    font-size 40px
    line-height 56px
    padding-top 74px
    margin-bottom 20px
  .sub
    font-size 16px
    margin-bottom 50px
  .btn
    width 140px
    height 40px
    border-radius 2px
    color #2B303B
    background-color #3AD682
    font-size 14px
    user-select none
    cursor pointer
.common-title
  font-size 26px
  line-height 36px
  color #333
  font-weight bold
  text-align center
  margin-bottom 35px
.service
  background-color #F1F4F8
  padding-top 50px
  padding-bottom 60px
  p
    margin 0
  &-list
    &-inner
      position relative
      padding 0 25px
  &-item
    float left
    width 33.33%
    padding 0 25px
    margin-bottom 40px
    &-inner
      background-color #fff
      border-radius 4px
      padding 22px 20px 0
  &-title
    font-size 18px
    color #333
    line-height 25px
    margin-bottom 12px
  &-subject
    font-size 14px
    color #666
    height 46px
    line-height 23px
    box-sizing content-box
    padding-bottom 14px
    border-bottom 1px solid #eee
  &-more
    line-height 48px
    color #3AD682
    font-size 14px
    font-weight bold
.industry
  height 500px
  background url("../assets/images/industry_bg.png") center no-repeat
  background-size cover
  position relative
  // opacity 0
  &-fade
    animation fade 1s
    animation-fill-mode forwards
  &-title
    color #fff
    font-size 26px
    font-weight bold
    text-align center
    padding 50px 0 42px
  &-main
    width 815px
    color #fff
  &-subtitle
    font-size 25px
    margin-bottom 30px
  &-subject
    line-height 26px
    font-size 14px
    margin-bottom 50px
  &-btn
    width 140px
    height 40px
    border-radius 2px
    color #2B303B
    background-color #3AD682
    font-size 14px
    user-select none
    cursor pointer
  .nav
    position absolute
    right 48px
    top 50%
    transform translateY(-50%)
    &-item
      width 220px
      height 36px
      display flex
      align-items center
      padding-left 20px
      color: #fff
      font-weight bold
      cursor pointer
      transition .2s all ease
      &:hover,
      &-active
        background: rgba(255, 255, 255, 0.1);
        color: #3AD682
.partner
  padding-bottom 40px
  .partner-inner
    text-align center
  .industry-title
    color #333
    padding-bottom 10px
  img
    width 1154px
    height 256px
.expert
  margin-bottom 60px
  .expert-inner
    text-align center
  .industry-title
    color #333
    padding-bottom 20px
  .expert-desc
    font-size 20px
    color #777
    line-height 2
  .expert-btn
    display inline-flex
    width 114px
    height 40px
    border-radius 2px
    color #2B303B
    background-color #3AD682
    font-size 18px
    user-select none
    cursor pointer
    color #fff
    margin-top 20px
@keyframes fade {
    from {
      opacity: 0.6;
    }
    to {
      opacity: 1;
    }
}
</style>